import React from 'react';
import * as S from './Footer.styled';
import { DownloadIcon } from '../../../../assets/icons/icons';
import { useMediaQuery } from 'react-responsive';
const Footer = () => {
  const AndroidVersion = /Android/i.test(navigator.userAgent);
  const isMobile = useMediaQuery({
    query: 'screen and (max-width: 800px)',
  });
  return (
    <S.Wrapper>
      {isMobile && AndroidVersion && (
        <S.DownloadLink
          target="_blank"
          download
          to={`https://gvvouchers.co.za/download/gvvoucher.apk`}>
          <S.DownloadImage>
            <DownloadIcon />
          </S.DownloadImage>
          downlaod app
        </S.DownloadLink>
      )}
    </S.Wrapper>
  );
};

export default Footer;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(214, 195, 217, 1) 20%,
    rgba(255, 194, 183, 1) 100%
  );
  padding-top: 2.75rem;
  padding-bottom: 4.87rem;
`;

export const WrapperInner = styled.div`
  width: 82%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: 92%;
  }
`;
export const Title = styled.div`
  font-size: 3rem;
  line-height: 1.35;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4.5rem;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const ServicesList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const ServicesItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  flex-shrink: 0;

  @media screen and (min-width: 801px) {
    border-top: 1px solid var(--ig-black);
    &:not(:nth-child(4n)) {
      border-right: 1px solid var(--ig-black);
    }

    &:nth-child(-n + 4) {
      border-top: none;
    }
  }

  @media screen and (max-width: 800px) {
    width: 50%;
    border-bottom: 1px solid var(--ig-black);

    &:not(:nth-child(2n)) {
      border-right: 1px solid var(--ig-black);
    }

    &:nth-last-child(2) {
      border-bottom: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
`;

export const ServicesImage = styled.span`
  width: 226px;
  height: 111px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const ServicesLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.25rem 3.12rem;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 78px 120px 0 rgba(0, 9.000000000000039, 78, 0.1);

    ${ServicesImage} {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 800px) {
    padding: 1.25rem 1.25rem;
  }
`;

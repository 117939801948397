import React from 'react';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './theme/GlobyleStyles';
import ReactDOM from 'react-dom/client';
import './theme/font.css';
import Router from './router';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Router />
  </React.StrictMode>,
);

reportWebVitals();

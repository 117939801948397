import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  background-color: var(--ig-rose2);
  display: flex;
  justify-content: space-between;
  padding-top: 5.37rem;
  padding-bottom: 4.31rem;
  padding-left: 13%;
  overflow-x: hidden;

  @media screen and (max-width: 1024px) {
    padding-left: 4%;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

export const ShopInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 44.3%;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 2rem;
    align-items: flex-start;
  }
`;

export const ShopTitle = styled.div`
  font-size: 3rem;
  line-height: 1.2;
  color: var(--ig-black);
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: left;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const ShopDesc = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--ig-grey);
  margin-bottom: 1.56rem;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const ShopButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.87rem 0 1.87rem 0;
  background-color: var(--ig-rose3);
  color: var(--ig-white);
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1.5rem 3rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    filter: brightness(0.9);
  }

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
    padding: 1.06rem 2.5rem 1.06rem 2.5rem;
    border-radius: 3.12rem;
  }
`;

export const ShopImage = styled.div`
  width: 44.3%;
  flex-shrink: 0;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 800px) {
    width: 80%;
  }
`;

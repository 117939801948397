import React, { useState } from 'react';
import { ImgContainer } from '../../theme/GlobyleStyles';
import * as S from './Vouchers.styled';
import VoucherBg from '../../assets/images/Vouchers/Soleo.png';
import VoucherImage from '../../assets/images/Vouchers/SoleoSmall.png';

const Option = ['R2', 'R5', 'R12'];
const Vouchers = () => {
  const [selectedOption, setSelectedOption] = useState();
  const [count, setCount] = useState(1);
  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  return (
    <S.Wrapper>
      <S.AirBg>
        <ImgContainer>
          <img src={VoucherBg} alt="airtime" />
        </ImgContainer>
      </S.AirBg>
      <S.VouchersContainer>
        <S.ProductInfo>
          <S.ProductImage>
            <ImgContainer>
              <img src={VoucherImage} alt="soleo" />
            </ImgContainer>
          </S.ProductImage>
          <S.ProductMain>
            <S.ProductAir>Gift Voucher</S.ProductAir>
            <S.MainName>Soleo Jewellery</S.MainName>
            <S.MainCount>R500.00 - R1500.00</S.MainCount>
            <S.ProductAir>Amount</S.ProductAir>
            <S.Form>
              <S.Select value={selectedOption} onChange={handleSelectChange}>
                <S.Option value={''}>Choose an Option</S.Option>
                {Option.map(option => (
                  <S.Option key={option} value={option}>
                    {option}
                  </S.Option>
                ))}
              </S.Select>
              <S.BottomInfo>
                <S.CountLabel>
                  <S.CountInput
                    value={count}
                    onChange={e => setCount(e.target.value)}
                  />
                </S.CountLabel>
                <S.AddButton>Add to Cart</S.AddButton>
              </S.BottomInfo>
            </S.Form>
          </S.ProductMain>
        </S.ProductInfo>
      </S.VouchersContainer>
    </S.Wrapper>
  );
};

export default Vouchers;

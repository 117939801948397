import styled from 'styled-components';
import BgIcon from '../../../../assets/images/gv-vouchers/gv-vouchers.jfif';
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-image: url(${BgIcon});
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: 34% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3.62rem;
  padding-top: 3.62rem;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 0.7rem;
    background-size: cover;
  }
`;

export const MobileLogo = styled.div`
  position: absolute;
  width: 26.52%;
  min-width: 300px;
  max-width: 400px;
  left: 1.37rem;
  bottom: -3.75rem;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 90.57%;
  }

  @media screen and (max-width: 1024px) {
    min-width: unset;
    max-width: 215px;
    left: 0.5rem;
    bottom: -2.5rem;
  }

  @media screen and (max-width: 800px) {
    position: static;
    width: 100%;
    max-width: unset;
  }
`;

export const VoucherLogo = styled.div`
  position: absolute;
  width: 26.52%;
  min-width: 300px;
  max-width: 400px;
  right: 1.37rem;
  top: 1.93rem;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 74%;
  }

  @media screen and (max-width: 1024px) {
    max-width: 215px;
    min-width: unset;
  }

  @media screen and (max-width: 800px) {
    position: static;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
export const Title = styled.div`
  color: var(--ig-blue);
  font-size: 4.75rem;
  font-weight: 500;
  text-shadow: 5px 2px 0 var(--ig-blue2);
  text-align: center;
  margin-bottom: 1.25rem;

  @media screen and (max-width: 800px) {
    font-size: 2.25rem;
    font-weight: 700;
  }
`;

export const Desc = styled.div`
  color: var(--ig-blue2);
  font-size: 1.56rem;
  text-align: center;
  word-break: break-word;

  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
`;

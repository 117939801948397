import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 32.62rem;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 800px) {
    height: 20rem;
  }

  @media screen and (max-width: 500px) {
    height: 15rem;
  }

  @media screen and (max-width: 350px) {
    height: 10rem;
  }
`;

export const Star = styled.div`
  position: relative;
  width: 61.31%;
  height: 100%;
  left: -32%;
  top: -20%;
  bottom: 0;
  transition: all 0.8s;
  transform: rotate(${props => props.position});
  &:before {
    content: '';
    padding-top: 97.5%;
  }

  svg * {
    transition: all 0.4s;
    width: 100%;
    height: 100%;
    fill: ${props => props.fill};
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 72.43%;
  position: absolute;
  right: 4.16%;
  top: 30%;
  transform: translateY(-30%);
  opacity: ${props => props.op};
  transition: opacity 0.8s ease-in-out;
`;

export const InfoName = styled.div`
  font-size: 3rem;
  color: var(--ig-black);
  font-weight: 700;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const InfoImage = styled.div`
  width: 100%;
  position: relative;
  transition: all 0.4s;

  &:before {
    content: '';
    display: block;
    padding-top: 25.11%;
  }

  img {
    transition: all 0.4s;
    width: 100%;
    height: 100%;
  }
`;

import React, { useEffect } from 'react';
import * as S from './GvVouchers.styled';
import MobileImage from '../../../../assets/images/gv-vouchers/hand.png';
import VoucherImage from '../../../../assets/images/gv-vouchers/voucher.png';
import { ImgContainer } from '../../../../theme/GlobyleStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';

const GvVouchers = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 200,
      once: true,
    });
  }, []);
  return (
    <S.Wrapper>
      <S.VoucherLogo data-aos="fade-left">
        <ImgContainer>
          <img src={VoucherImage} alt="" />
        </ImgContainer>
      </S.VoucherLogo>
      <S.Text data-aos="fade-up">
        <S.Title>
          GV <br />
          Vouchers
        </S.Title>
        <S.Desc>Your One-Stop Destination for Digital Vouchers</S.Desc>
      </S.Text>
      <S.MobileLogo data-aos="fade-right">
        <ImgContainer>
          <img src={MobileImage} alt="hand" />
        </ImgContainer>
      </S.MobileLogo>
    </S.Wrapper>
  );
};

export default GvVouchers;

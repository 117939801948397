import React, { useEffect } from 'react';
import * as S from './Shop.styled';
import ShopImage from '../../../../assets/images/Shop.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useParams } from 'react-router-dom';
const Shop = () => {
  const { lang } = useParams();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 200,
      once: true,
    });
  }, []);
  return (
    <S.Wrapper>
      <S.ShopInfo data-aos="fade-right">
        <S.ShopTitle>Receive Your Voucher Instantly</S.ShopTitle>
        <S.ShopDesc>
          Our swift service ensures your voucher is available immediately, so
          you can focus on what really matters
        </S.ShopDesc>
        <S.ShopButton to={`/${lang}/shop`}>Shop Now</S.ShopButton>
      </S.ShopInfo>
      <S.ShopImage data-aos="fade-left">
        <img src={ShopImage} alt="" />
      </S.ShopImage>
    </S.Wrapper>
  );
};

export default Shop;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16rem;

  @media screen and (max-width: 800px) {
    padding-bottom: 3rem;
  }
`;

export const AirBg = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 17.5%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 500px) {
    &:before {
      padding-top: 33.5%;
    }
  }
`;

export const AirContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75.69%;
  margin: 0 auto;
  padding-top: 4.62rem;

  @media screen and (max-width: 800px) {
    width: 90%;
    padding-top: 2rem;
  }
`;

export const AirList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const AirItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(25% - 1.96rem);
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    width: calc(33.33% - 1.96rem);
  }

  @media screen and (max-width: 630px) {
    width: calc(50% - 1.96rem);
  }

  @media screen and (max-width: 420px) {
    width: 100%;
  }
`;

export const AirItemLink = styled(Link)`
  position: relative;
  width: 100%;
  margin-bottom: 0.3rem;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const AirItemName = styled.div`
  font-size: 0.87rem;
  line-height: 1.21;
  color: var(--ig-grey3);
  font-weight: 500;
  display: flex;
`;

export const AirPrice = styled.div`
  font-size: 0.87rem;
  line-height: 1.21;
  color: var(--ig-black);
  font-weight: 700;
  display: flex;
  margin-bottom: 1.5rem;
`;

export const AirButton = styled(Link)`
  text-decoration: none;
  background-color: var(--ig-grey1);
  border-radius: 0 1.87rem 0 1.87rem;
  padding: 0.62rem 2.25rem;
  font-size: 0.87rem;
  font-weight: 600;
  color: ${props => props.color};
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 1100px) {
    padding: 0.62rem 2rem;
  }
`;

import React from 'react';
import GvVouchers from './components/GvVouchers/GvVouchers';
import Options from './components/Options/Options';
import Services from './components/Services/Services';
import Shop from './components/Shop/Shop';
import Banners from './components/Banners/Banners';

const Home = () => {
  return (
    <div>
      <GvVouchers />
      <Options />
      <Banners />
      <Services />
      <Shop />
    </div>
  );
};

export default Home;

import React from 'react';
import * as S from './Airtime.styled';
import { ImgContainer } from '../../theme/GlobyleStyles';

const AirtimeItem = ({ item }) => {
  return (
    <S.AirItem key={item.name}>
      <S.AirItemLink to={item.path}>
        <ImgContainer>
          <img src={item.image} alt={item.name} />
        </ImgContainer>
      </S.AirItemLink>
      <S.AirItemName>Airtime</S.AirItemName>
      <S.AirPrice>R2.00 - R275.00</S.AirPrice>
      <S.AirButton
        to={item.path}
        color={
          item.name === 'Vodacom'
            ? 'var(--ig-red)'
            : item.name === 'Mtn'
              ? 'var(--ig-yellow)'
              : item.name === 'Cell'
                ? 'var(--ig-orange)'
                : 'var(--ig-blue-light)'
        }>
        Select Option
      </S.AirButton>
    </S.AirItem>
  );
};

export default AirtimeItem;

import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root{
    --ig-purple: #C2BBEE;
    --ig-rose:#FFEADA; 
    --ig-rose2:#FEDEC6;
    --ig-rose3:#FDC3BA;  
    --ig-blue: #9acedb;
    --ig-blue2: #2e2854;
    --ig-blue3:#7FB1EE;
    --ig-blue-light:#0081C2;
    --ig-link-active:#3474C3;
    --ig-black: #000;
    --ig-black1:#252525;
    --ig-white:#fff; 
    --ig-grey:#626262;
    --ig-grey1:#ACACAC;
    --ig-grey2: #727272;
    --ig-grey3: #9F9F9F;
    --ig-grey4:#ababab; 
    --ig-shopBg:rgba(217, 217, 217, 0.25);
    --ig-red: #FF0000;
    --ig-red-dark:#C91F0C;
    --ig-yellow:#F7C716;
    --ig-orange:#E94E0E;
    
    
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 1px;
      border-radius: 3px;
    }
  }  

  body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    font-family: 'Rubik', sans-serif;
  }

`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default GlobalStyle;

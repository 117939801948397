import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  background-color: var(--ig-purple);
  height: 119px;
  margin-top: auto;
  padding: 2rem 2rem;
`;

export const DownloadLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--ig-grey);
  font-size: 1.3rem;
`;

export const DownloadImage = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;

  svg {
    max-width: 100%;
    max-height: 100%;
    fill: var(--ig-grey);
  }
`;

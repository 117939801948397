import React, { useEffect } from 'react';
import * as S from './Options.styled';
import {
  GiftIcon,
  PaymentIcon,
  SettingsIcon,
} from '../../../../assets/icons/icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Options = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 200,
      once: true,
    });
  }, []);
  return (
    <S.Wrapper>
      <S.WrapperInner>
        <S.BackgroundLeft />
        <S.OptionList>
          <S.OptionItem data-aos="fade-right">
            <S.OptionTitle>Countless Options</S.OptionTitle>
            <S.OptionDesc>
              Browse our website to recharge on a wide variety of Prepaid
              Services
            </S.OptionDesc>
            <S.OptionImage>
              <SettingsIcon />
            </S.OptionImage>
          </S.OptionItem>
          <S.OptionItem data-aos="fade-up">
            <S.OptionTitle>Gift Vouchers</S.OptionTitle>
            <S.OptionDesc>
              Gift Vouchers redeemed at selected outlets available
            </S.OptionDesc>
            <S.OptionImage>
              <GiftIcon />
            </S.OptionImage>
          </S.OptionItem>
          <S.OptionItem data-aos="fade-left">
            <S.OptionTitle>Easy Secure Payment</S.OptionTitle>
            <S.OptionDesc>
              Keep your info safe. Our encrypted payment gateway ensures your
              security
            </S.OptionDesc>
            <S.OptionImage>
              <PaymentIcon />
            </S.OptionImage>
          </S.OptionItem>
        </S.OptionList>
      </S.WrapperInner>
    </S.Wrapper>
  );
};

export default Options;

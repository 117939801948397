import React, { useEffect, useState } from 'react';
import * as S from './Banners.styled';
import { StarIcon } from '../../../../assets/icons/icons';
import { ImgContainer } from '../../../../theme/GlobyleStyles';
import VodacomImage from '../../../../assets/images/Banners/vodacom.png';

const Banners = () => {
  const items = [
    {
      position: '0deg',
      name: '',
      image: VodacomImage,
      color: 'var(--ig-red-dark)',
      text: 'Vodacom Airtime',
      price: 'R2 - R275',
    },

    {
      position: '50deg',
      name: '',
      image: VodacomImage,
      color: 'var(--ig-orange)',
      text: 'Mtn Airtime',
      price: 'R500 - R1500',
    },

    {
      position: '100deg',
      name: '',
      image: VodacomImage,
      color: 'var(--ig-blue-light)',
      text: 'Telkom Airtime',
      price: 'R10 - R300',
    },

    {
      position: '150deg',
      name: '',
      image: VodacomImage,
      color: 'var(--ig-yellow)',
      text: 'Cell C Airtime',
      price: 'R90 - R390',
    },
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(i => i + 1);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <S.Wrapper>
      <S.Star
        fill={items[index % items.length].color}
        position={`calc(360deg/7*${index})`}>
        <ImgContainer>
          <StarIcon />
        </ImgContainer>
      </S.Star>
      {[-1, 0, 1].map(x => {
        const item = items[(items.length + index + x) % items.length];
        return (
          <S.Info key={index + x} op={1 - Math.abs(x)}>
            <S.InfoName>{item.text}</S.InfoName>
            <S.InfoName>{item.price}</S.InfoName>
            <S.InfoImage>
              <ImgContainer>
                <img src={item.image} alt="" />
              </ImgContainer>
            </S.InfoImage>
          </S.Info>
        );
      })}
    </S.Wrapper>
  );
};

export default Banners;

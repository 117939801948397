import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductBanner = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-top: 16.11%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 73.26%;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 7.12rem;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -10%;
    right: -10%;
    background-color: var(--ig-black);
    height: 1px;
  }

  @media screen and (max-width: 1024px) {
    &:after {
      left: 0;
      right: 0;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ProductImage = styled.div`
  width: 41.13%;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const ProductMain = styled.div`
  width: 41.13%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const ProductAir = styled.div`
  color: var(--ig-link-active);
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2.6rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
`;

export const MainName = styled.div`
  color: var(--ig-black);
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.18rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
`;

export const MainCount = styled.div`
  margin-bottom: 1.75rem;
  font-size: 2rem;
  font-weight: 700;
  color: var(--ig-black);

  @media screen and (max-width: 800px) {
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Select = styled.select`
  position: relative;
  width: 100%;
  padding: 1.25rem;
  border-radius: 1.87rem;
  outline: none;
  margin-bottom: 1.81rem;
  appearance: none;
  font-size: 1.25rem;
  color: var(--ig-grey4);

  @media screen and (max-width: 800px) {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    padding: 0.8rem;
  }
`;

export const Option = styled.option``;

export const BottomInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const CountLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.43rem;
  height: 51px;
  margin-right: 5rem;

  @media screen and (max-width: 800px) {
    width: 3rem;
    margin-right: 2rem;
  }
`;
export const CountInput = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid var(--ig-black);
  text-align: center;
  outline: none;
  background-color: transparent;
  font-size: 2rem;
  font-weight: 700;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const AddButton = styled.button`
  background-color: var(--ig-blue3);
  color: var(--ig-white);
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.87rem 1.68rem;
  border: none;
  border-radius: 1.87rem 0 1.87rem 0;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 800px) {
    padding: 0.5rem 1.2rem;
  }
`;

export const Related = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3.25rem;
`;

export const RelatedName = styled.div`
  font-size: 4rem;
  font-weight: 400;
  color: var(--ig-black);
  margin-bottom: 3.5rem;

  @media screen and (max-width: 1024px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const RelatedList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-between;
`;

export const RelatedItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 3 - 2.5rem);
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    width: calc(33.33% - 1.96rem);
  }

  @media screen and (max-width: 630px) {
    width: calc(50% - 1.96rem);
  }

  @media screen and (max-width: 420px) {
    width: calc(50% - 0.5rem);
  }

  @media screen and (max-width: 365px) {
    width: 100%;
  }
`;

export const RelatedItemLink = styled(Link)`
  position: relative;
  width: 100%;
  margin-bottom: 0.3rem;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const RelatedItemName = styled.div`
  font-size: 0.87rem;
  line-height: 1.21;
  color: var(--ig-grey3);
  font-weight: 500;
  display: flex;
`;

export const RelatedPrice = styled.div`
  font-size: 0.87rem;
  line-height: 1.21;
  color: var(--ig-black);
  font-weight: 700;
  display: flex;
  margin-bottom: 1.5rem;
`;

export const RelatedButton = styled(Link)`
  text-decoration: none;
  background-color: var(--ig-grey1);
  border-radius: 0 1.87rem 0 1.87rem;
  padding: 0.62rem 2.25rem;
  font-size: 0.87rem;
  font-weight: 600;
  color: ${props => props.color};
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 1100px) {
    padding: 0.62rem 2rem;
  }
`;

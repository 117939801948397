import React, { useEffect, useState } from 'react';
import * as S from './Heaeder.styled';
import Logo from '../../../../assets/images/logo.png';
import { BagIcon } from '../../../../assets/icons/icons';
import { useMediaQuery } from 'react-responsive';

const NAV_LINKS = [
  {
    name: 'Home',
    path: 'home',
  },
  {
    name: 'Buy Now',
    path: 'shop',
  },
  {
    name: 'About',
    path: '/',
  },
  {
    name: 'Contact Us',
    path: '/',
  },
];
const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const isMobile = useMediaQuery({
    query: 'screen and (max-width: 800px)',
  });

  useEffect(() => {
    if (isMenuActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuActive]);

  return (
    <S.Wrapper>
      <S.Logo to={'home'}>
        <img src={Logo} alt="Logo" />
      </S.Logo>
      {!isMobile ? (
        <S.Nav>
          <S.NavLinks>
            {NAV_LINKS.map(item => (
              <S.NavItem key={item.name} to={item.path}>
                {item.name}
              </S.NavItem>
            ))}
          </S.NavLinks>
          <S.BagIcon>
            <BagIcon />
            <S.BagCount>0</S.BagCount>
          </S.BagIcon>
        </S.Nav>
      ) : (
        <S.Burger
          onClick={() => setIsMenuActive(prev => !prev)}
          active={isMenuActive}>
          <span />
          <span />
          <span />
        </S.Burger>
      )}
      {isMobile && (
        <S.MobileMenu active={isMenuActive}>
          <S.MobileOutside onClick={() => setIsMenuActive(false)} />
          <S.MobileMenuWrapper>
            <S.MobileMenuList>
              {NAV_LINKS.map(item => (
                <S.MobileMenuItem key={item.name}>
                  <S.MobileMenuLink
                    to={item.path}
                    onClick={() => setIsMenuActive(false)}>
                    {item.name}
                  </S.MobileMenuLink>
                </S.MobileMenuItem>
              ))}
            </S.MobileMenuList>
          </S.MobileMenuWrapper>
        </S.MobileMenu>
      )}
    </S.Wrapper>
  );
};

export default Header;

import React, { useState } from 'react';
import * as S from './Product.styled';
import { ImgContainer } from '../../theme/GlobyleStyles';

const ProductInfo = ({ data }) => {
  const [selectedOption, setSelectedOption] = useState();
  const [count, setCount] = useState(1);
  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  return (
    <S.ProductInfo>
      <S.ProductImage>
        <ImgContainer>
          <img src={data.image} alt="" />
        </ImgContainer>
      </S.ProductImage>
      <S.ProductMain>
        <S.ProductAir>Airtime</S.ProductAir>
        <S.MainName>{data.name}</S.MainName>
        <S.MainCount>{data.price}</S.MainCount>
        <S.ProductAir>Amount</S.ProductAir>
        <S.Form>
          <S.Select value={selectedOption} onChange={handleSelectChange}>
            <S.Option value={''}>Choose an Option</S.Option>
            {data.option.map(option => (
              <S.Option key={option} value={option}>
                {option}
              </S.Option>
            ))}
          </S.Select>
          <S.BottomInfo>
            <S.CountLabel>
              <S.CountInput
                value={count}
                onChange={e => setCount(e.target.value)}
              />
            </S.CountLabel>
            <S.AddButton>Add to Cart</S.AddButton>
          </S.BottomInfo>
        </S.Form>
      </S.ProductMain>
    </S.ProductInfo>
  );
};

export default ProductInfo;

import React from 'react';
import * as S from './ShopNow.styled';
import Banner from '../../assets/images/ShopNow/ShopBg.png';
import { ImgContainer } from '../../theme/GlobyleStyles';
import AirtimeImage from '../../assets/images/ShopNow/Airtime.png';
import GiftImage from '../../assets/images/ShopNow/Gift.png';
import ElectriCity from '../../assets/images/ShopNow/Electricity.png';
import Streaming from '../../assets/images/ShopNow/Streaming.png';

const SHOP_LIST = [
  {
    name: 'Airtime',
    image: AirtimeImage,
    path: 'airtime',
  },
  {
    name: 'Gift Vouchers',
    image: GiftImage,
    path: 'gift-vouchers',
  },
  {
    name: 'Prepaid Electricity',
    image: ElectriCity,
  },
  {
    name: 'Streaming Services',
    image: Streaming,
  },
];
const ShopNow = () => {
  return (
    <S.Wrapper>
      <S.ShopNowBanner>
        <ImgContainer>
          <img src={Banner} alt="banner" />
        </ImgContainer>
      </S.ShopNowBanner>
      <S.ShopContainer>
        <S.ShopList>
          {SHOP_LIST.map(item => (
            <S.ShopItem key={item.name}>
              <S.ShopLink to={item.path}>
                <S.ShopImage>
                  <ImgContainer>
                    <img src={item.image} alt={item.name} />
                  </ImgContainer>
                </S.ShopImage>
                <S.ShopInfo>
                  <S.ShopInfoName>{item.name}</S.ShopInfoName>
                  <S.ShopInfoCount>1 product</S.ShopInfoCount>
                </S.ShopInfo>
              </S.ShopLink>
            </S.ShopItem>
          ))}
        </S.ShopList>
      </S.ShopContainer>
    </S.Wrapper>
  );
};

export default ShopNow;

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import Layout from './pages/Layout/Layout';
import Home from './pages/Home/Home';
import ShopNow from './pages/ShopNow/ShopNow';
import Airtime from './pages/Airtime/Airtime';
import Product from './pages/Product/Product';
import Vouchers from './pages/Vouchers/Vouchers';

export default function Router() {
  const defaultLanguage = 'en';
  const redirectUrl = `/${defaultLanguage}/home`;

  const router = createBrowserRouter([
    { path: '/', element: <Navigate to={`${redirectUrl}`} replace /> },
    {
      path: '/:lang',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Navigate to={`home`} replace />,
        },
        {
          path: 'home',
          element: <Home />,
        },
        {
          path: 'shop',
          element: <ShopNow />,
        },
        {
          path: 'shop/airtime',
          element: <Airtime />,
        },
        {
          path: 'shop/airtime/:selected',
          element: <Product />,
        },
        {
          path: 'shop/gift-vouchers',
          element: <Vouchers />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

import React from 'react';
import * as S from './Product.styled';
import VodacomImage from '../../assets/images/Products/Vodacom.jpg';
import MtnImage from '../../assets/images/Products/Mtn.jpg';
import CellImage from '../../assets/images/Products/Cell.jpg';
import TelkomImage from '../../assets/images/Products/Telkom.jpg';
import VodacomImage1 from '../../assets/images/Products/vodacom-1.png';
import MtnImage1 from '../../assets/images/Products/mtn-1.png';
import CellImage1 from '../../assets/images/Products/cell-1.png';
import TelkomImage1 from '../../assets/images/Products/telkom-1.png';

import { ImgContainer } from '../../theme/GlobyleStyles';
import { useParams } from 'react-router-dom';
import ProductInfo from './ProductInfo';

const bannerImage = {
  vodacom: VodacomImage,
  mtn: MtnImage,
  cell: CellImage,
  telkom: TelkomImage,
};

const productInfo = {
  vodacom: {
    name: 'Vodacom Airtime',
    price: 'R2.00 - R275.00',
    image: VodacomImage1,
    option: ['R2', 'R5', 'R12'],
  },
  mtn: {
    name: 'MTN Airtime',
    price: 'R2.00 - R275.00',
    image: MtnImage1,
    option: ['R2', 'R5', 'R12'],
  },
  cell: {
    name: 'Cell C Airtime',
    price: 'R2.00 - R275.00',
    image: CellImage1,
    option: ['R2', 'R5', 'R12'],
  },
  telkom: {
    name: 'Telkom Airtime',
    price: 'R2.00 - R275.00',
    image: TelkomImage1,
    option: ['R2', 'R5', 'R12'],
  },
};

const RelatedList = [
  {
    name: 'vodacom',
    image: VodacomImage1,
    color: 'var(--ig-red)',
  },
  {
    name: 'telkom',
    image: TelkomImage1,
    color: 'var(--ig-blue-light)',
  },
  {
    name: 'mtn',
    image: MtnImage1,
    path: 'shop/airtime',
    color: 'var(--ig-yellow)',
  },
  {
    name: 'cell',
    image: CellImage1,
    path: 'shop/airtime',
    color: 'var(--ig-orange)',
  },
];
const Product = () => {
  const { selected } = useParams();

  return (
    <S.Wrapper>
      <S.ProductBanner>
        <ImgContainer>
          <img src={bannerImage[selected]} alt="banner" />
        </ImgContainer>
      </S.ProductBanner>
      <S.Container>
        <ProductInfo data={productInfo[selected]} />
        <S.Related>
          <S.RelatedName>Related Products</S.RelatedName>
          <S.RelatedList>
            {RelatedList.filter(e => e.name !== selected).map(item => (
              <S.RelatedItem key={item.name}>
                <S.RelatedItemLink to={`../shop/airtime/${item.name}`}>
                  <ImgContainer>
                    <img src={item.image} alt={item.name} />
                  </ImgContainer>
                </S.RelatedItemLink>
                <S.RelatedItemName>Airtime</S.RelatedItemName>
                <S.RelatedPrice>R2.00 - R275.00</S.RelatedPrice>
                <S.RelatedButton
                  to={`../shop/airtime/${item.name}`}
                  color={item.color}>
                  Select Option
                </S.RelatedButton>
              </S.RelatedItem>
            ))}
          </S.RelatedList>
        </S.Related>
      </S.Container>
    </S.Wrapper>
  );
};

export default Product;

import React from 'react';
import { Outlet } from 'react-router-dom';
import * as S from './Layout.styled';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
const Layout = () => {
  return (
    <S.WrapperOuter>
      <S.Wrapper>
        <Header />
        <S.Container>
          <Outlet />
        </S.Container>
        <Footer />
      </S.Wrapper>
    </S.WrapperOuter>
  );
};

export default Layout;

import React from 'react';
import * as S from './Airtime.styled';
import AirBg from '../../assets/images/Airtime/Airtime.jpg';
import { ImgContainer } from '../../theme/GlobyleStyles';
import VodacomImage from '../../assets/images/Airtime/vodacom.jpg';
import MtnImage from '../../assets/images/Airtime/mtn.jpg';
import CellImage from '../../assets/images/Airtime/cellc.jpg';
import TelekomImage from '../../assets/images/Airtime/telkom.jpg';
import AirtimeItem from './AirtimeItem';

const SHOP_LIST = [
  {
    name: 'Vodacom',
    image: VodacomImage,
    path: 'vodacom',
  },
  {
    name: 'Mtn',
    image: MtnImage,
    path: 'mtn',
  },
  {
    name: 'Cell',
    image: CellImage,
    path: 'cell',
  },
  {
    name: 'Telkom',
    image: TelekomImage,
    path: 'telkom',
  },
];
const Airtime = () => {
  return (
    <S.Wrapper>
      <S.AirBg>
        <ImgContainer>
          <img src={AirBg} alt="airtime" />
        </ImgContainer>
      </S.AirBg>
      <S.AirContainer>
        <S.AirList>
          {SHOP_LIST.map(item => (
            <AirtimeItem key={item.name} item={item} />
          ))}
        </S.AirList>
      </S.AirContainer>
    </S.Wrapper>
  );
};

export default Airtime;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2.25rem;
`;

export const ShopNowBanner = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 17.5%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 500px) {
    &:before {
      padding-top: 22.5%;
    }
  }
`;

export const ShopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 91.66%;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    width: 95%;
  }
`;

export const ShopList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ShopItem = styled.li`
  display: flex;
  align-content: flex-start;
  width: calc(25% - 1.87rem);

  @media screen and (max-width: 1024px) {
    width: calc(33.33% - 1.87rem);
  }

  @media screen and (max-width: 800px) {
    width: calc(50% - 1rem);
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ShopLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  width: 100%;
`;

export const ShopImage = styled.span`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ShopInfo = styled.span`
  border-radius: 0 1.87rem 0 1.87rem;
  background-color: var(--ig-shopBg);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 85%;
  padding: 1.12rem 0.5rem;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ShopInfoName = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: var(--ig-black);
  text-align: center;
  line-height: 0.47;
  margin-bottom: 0.3rem;
`;

export const ShopInfoCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: var(--ig-grey1);
  font-size: 0.7rem;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AirBg = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 17.5%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 500px) {
    &:before {
      padding-top: 33.5%;
    }
  }
`;

export const VouchersContainer = styled.div`
  width: 81.66%;
  margin-left: 4.51%;
  margin-right: auto;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
  }
`;
export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7rem;
  position: relative;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
export const ProductImage = styled.div`
  width: 48.89%;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 68.86%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;
export const ProductMain = styled.div`
  width: 41.13%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const ProductAir = styled.div`
  color: var(--ig-link-active);
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2.6rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
`;

export const MainName = styled.div`
  color: var(--ig-black);
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.18rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
`;

export const MainCount = styled.div`
  margin-bottom: 1.75rem;
  font-size: 2rem;
  font-weight: 700;
  color: var(--ig-black);

  @media screen and (max-width: 800px) {
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Select = styled.select`
  position: relative;
  width: 100%;
  padding: 1.25rem;
  border-radius: 1.87rem;
  outline: none;
  margin-bottom: 1.81rem;
  appearance: none;
  font-size: 1.25rem;
  color: var(--ig-grey4);

  @media screen and (max-width: 800px) {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    padding: 0.8rem;
  }
`;

export const Option = styled.option``;

export const BottomInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const CountLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.43rem;
  height: 51px;
  margin-right: 5rem;

  @media screen and (max-width: 800px) {
    width: 3rem;
    margin-right: 2rem;
  }
`;
export const CountInput = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid var(--ig-black);
  text-align: center;
  outline: none;
  background-color: transparent;
  font-size: 2rem;
  font-weight: 700;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const AddButton = styled.button`
  background-color: var(--ig-blue3);
  color: var(--ig-white);
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.87rem 1.68rem;
  border: none;
  border-radius: 1.87rem 0 1.87rem 0;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 800px) {
    padding: 0.5rem 1.2rem;
  }
`;

import styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--ig-purple);
  height: 7.43rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.62rem;
  padding-right: 5.68rem;

  @media screen and (max-width: 1024px) {
    padding-right: 1.7rem;
    height: 5rem;
  }
`;

export const Logo = styled(Link)`
  width: 9.75rem;
  height: 6.43rem;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 6.75rem;
    height: 3.43rem;

    img {
      object-fit: contain;
    }
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
`;

export const NavLinks = styled.div`
  font-family: 'Amiko', sans-serif;
  display: flex;
  align-items: center;
  margin-right: 12rem;

  @media screen and (max-width: 1024px) {
    margin-right: 4rem;
  }
`;

export const NavItem = styled(NavLink)`
  text-decoration: none;
  color: var(--ig-black);
  font-size: 1.06rem;
  line-height: 1.21;
  font-weight: 700;
  transition: all 0.2s ease-in-out;

  &:not(:last-child) {
    margin-right: 3.75rem;
  }

  &.active {
    color: var(--ig-link-active);
  }

  &:hover {
    color: var(--ig-link-active);
  }

  @media screen and (max-width: 1024px) {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

export const BagIcon = styled.div`
  position: relative;
  width: 1.18rem;
  height: 1rem;
  flex-shrink: 0;
  cursor: pointer;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const BagCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 1.12rem;
  height: 1.12rem;
  top: -10px;
  right: -12px;
  background-color: var(--ig-blue2);
  border-radius: 50%;
  color: var(--ig-white);
  font-size: 0.68rem;
  font-weight: 700;
`;

export const Burger = styled.div`
  display: flex;
  flex-direction: column;
  width: 1.56rem;
  height: 1.56rem;
  justify-content: space-between;
  cursor: pointer;
  z-index: 11;
  transition: all 0.9s;

  span {
    transition: all 0.8s;
    width: 100%;
    height: 0.38rem;
    border-radius: 5px;
    background-color: var(--ig-blue2);
  }

  ${props =>
    props.active &&
    css`
      span {
        background-color: var(--ig-white);
        &:first-child {
          transform: translateY(10px) rotate(45deg);
        }
        &:last-child {
          transform: translateY(-9px) rotate(-45deg);
        }
        &:nth-child(2n) {
          transform: translateX(500px);
        }
      }
    `}
`;

export const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  transform: translateX(100%);
  background-color: rgba(0, 0, 0, 0.83);
  transition: all 0.8s;

  ${props =>
    props.active &&
    css`
      transform: translateX(0);
    `}
`;

export const MobileOutside = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  width: 50%;
  margin-left: auto;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
`;

export const MobileMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const MobileMenuItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const MobileMenuLink = styled(NavLink)`
  text-decoration: none;
  color: var(--ig-white);
  display: flex;
  padding-left: 2rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  width: 100%;
`;

import styled from 'styled-components';
import Bg1 from '../../../../assets/images/Option/option-bg1.png';
import Bg2 from '../../../../assets/images/Option/option-bg2.png';
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  background-color: var(--ig-rose);
  background-image: url(${Bg1});
  background-position: 98% 40%;
  background-repeat: no-repeat;
  background-size: 660px auto;
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;

  @media screen and (max-width: 800px) {
    background-size: 30% auto;
    background-position: 0 0;
  }
`;

export const WrapperInner = styled.div`
  padding-top: 5.21rem;
  padding-bottom: 5.21rem;
  display: flex;
  flex-direction: column;
  width: 85%;

  @media screen and (max-width: 1024px) {
    width: 93%;
  }
`;

export const BackgroundLeft = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url(${Bg2});
  background-position: 7% 86%;
  background-repeat: no-repeat;
  background-size: 260px auto;
  transition:
    background 0.3s,
    border-radius 0.3s,
    opacity 0.3s;

  @media screen and (max-width: 800px) {
    background-size: 30% auto;
    background-position: 0 0;
  }
`;

export const OptionList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const OptionItem = styled.li`
  display: flex;
  flex-direction: column;
  background-color: var(--ig-white);
  z-index: 1;
  width: calc(100% / 3 - 1rem);
  max-width: 393px;
  border-radius: 50px 0 50px 0;
  padding-top: 2.75rem;
  padding-bottom: 1.05rem;
  padding-left: 1.87rem;
  padding-right: 1.25rem;
  margin-bottom: 1rem;

  &:nth-child(odd) {
    border-radius: 0 50px 0 50px;
  }

  &:not(:nth-child(3n)) {
    margin-right: 1.5rem;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 1.25rem;
    padding-right: 1rem;
  }

  @media screen and (max-width: 800px) {
    max-width: unset;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:not(:nth-child(3n)) {
      margin-right: 0;
    }
  }
`;
export const OptionTitle = styled.div`
  font-size: 1.56rem;
  font-weight: 900;
  margin-bottom: 2.25rem;
  color: var(--ig-black1);

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
`;

export const OptionDesc = styled.div`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.68rem;
  color: var(--ig-grey2);

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const OptionImage = styled.div`
  width: 6.18rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: auto;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 800px) {
    width: 3.18rem;
    height: 2.5rem;
  }
`;

import React, { useEffect } from 'react';
import * as S from './Services.styled';
import EskomImage from '../../../../assets/images/Services/eskom.png';
import MtnImage from '../../../../assets/images/Services/mtn.png';
import TelekomImage from '../../../../assets/images/Services/telekom.png';
import CellImage from '../../../../assets/images/Services/cell.png';
import SoleoImage from '../../../../assets/images/Services/soleo.png';
import MagicImage from '../../../../assets/images/Services/magic.png';
import LocalImage from '../../../../assets/images/Services/local.png';
import VodacomImage from '../../../../assets/images/Services/vodacom.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SERVICES_ITEMS = [
  {
    name: 'vodacom',
    icon: VodacomImage,
  },
  {
    name: 'eskom',
    icon: EskomImage,
  },
  {
    name: 'mtn',
    icon: MtnImage,
  },
  {
    name: 'telekom',
    icon: TelekomImage,
  },
  {
    name: 'cell',
    icon: CellImage,
  },
  {
    name: 'soleo',
    icon: SoleoImage,
  },
  {
    name: 'magic',
    icon: MagicImage,
  },
  {
    name: 'local',
    icon: LocalImage,
  },
];
const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 200,
      once: true,
    });
  }, []);
  return (
    <S.Wrapper>
      <S.WrapperInner>
        <S.Title>Get Prepaid Vouchers For Multiple Services</S.Title>
        <S.ServicesList>
          {SERVICES_ITEMS.map(item => (
            <S.ServicesItem key={item.name}>
              <S.ServicesLink>
                <S.ServicesImage data-aos="zoom-in">
                  <img src={item.icon} alt={item.name} />
                </S.ServicesImage>
              </S.ServicesLink>
            </S.ServicesItem>
          ))}
        </S.ServicesList>
      </S.WrapperInner>
    </S.Wrapper>
  );
};

export default Services;
